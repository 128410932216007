export const formItem = [
	{
		type: 'input',
		label:'编号',
		prop:'code',
		placeholder:'请输入'
	},
	{
		type: 'input',
		label:'名称',
		prop:'name',
		placeholder:'请输入'
	},
]
export const columns = [
	
	{
		title: "编号",
		dataIndex: "code",
	},
	{
		title: "名称",
		dataIndex: "name",
	},
	{
		title: "巡检模式",
		dataIndex: "type",
		customRender: function (type) {
			return '扫码'
		}
	},
	{
		title: "创建时间",
		dataIndex: "createDate",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "280",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}