import httpService from "@/request"

// 巡检点
// list
export function pointList(params) {
	return httpService({
		url: `/user/inspectionPoint/list`,
		method: 'get',
		params: params,
	})
}
export function pointInfo(params) {
	return httpService({
		url: `/user/inspectionPoint/findById`,
		method: 'get',
		params: params,
	})
}
export function pointInsert(params) {
	return httpService({
		url: `/user/inspectionPoint/insert`,
		method: 'post',
		data: params,
	})
}
export function pointDel(params) {
	return httpService({
		url: `/user/inspectionPoint/delete`,
		method: 'post',
		data: params,
	})
}
export function pointUpdate(params) {
	return httpService({
		url: `/user/inspectionPoint/update`,
		method: 'post',
		data: params,
	})
}
