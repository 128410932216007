export const form = {
	id:undefined,
	name:undefined,
	type:undefined,
	longitude:undefined,
	latitude:undefined,
	itemsList:[]
}
export const rules = {
	name:[{required:true,message:'请输入名称',trigger:'blur'}],
	type:[{required:true,message:'请选择分类',trigger:'change'}],
}
export const options = {
	status:[
		{ id:1, name:'未发布' },
		{ id:2, name:'已发布' },
	],
	categoryId:[]
}