<template>
  <div>
    <a-drawer
      :title="title"
      :width="720"
      :visible="show"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose"
    >
      <div class="drawer-content">
        基本信息
        <a-divider></a-divider>
        <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          layout="vertical"
        >
          <a-form-model-item label="名称" prop="name">
            <a-input v-model="form.name" style="width: 60%" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="类型" prop="type">
            <a-select v-model="form.type" style="width: 60%" placeholder="请选择">
				<a-select-option :value="1">扫码</a-select-option>
			</a-select>
          </a-form-model-item>
          <a-form-model-item label="经度" prop="longitude">
            <a-input v-model="form.longitude" style="width: 60%" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="纬度" prop="latitude">
            <a-input v-model="form.latitude" style="width: 60%" placeholder="请输入"></a-input>
          </a-form-model-item>
          <a-form-model-item label="检查项">
            <a-select
              style="width: 60%"
              v-model="itemArray"
              mode="tags"
              :token-separators="[',']"
              @change="handleChange"
            >
            </a-select>
          </a-form-model-item>
        </a-form-model>
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="addClose">
          关闭
        </a-button>
        <a-button type="primary" @click="submit"> 提交 </a-button>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { form, rules, options } from "./form.js";
import {
	pointInsert,
	pointUpdate,
	pointInfo,
} from "@/api/operation/inspection/point.js";
export default {
	props: {
		show: {
			type: Boolean,
			default: false,
		},
		editId: Number,
	},
	data() {
		return {
			title: "新增巡检点",
			form,
			rules,
			options,
			itemArray: [],
		};
	},
	mounted() {
	},
	methods: {
		handleChange(value) {
			console.log(`selected ${value}`);
		},
		addClose() {
			this.$refs.ruleForm.resetFields();
			this.form.itemsList = [];
			this.itemArray=[]
			this.$emit("addClose");
		},
		success() {
			this.$emit("success");
			this.addClose();
		},
		submit() {
			this.form.itemsList = [];
			for (let item in this.itemArray) {
				console.log(this.itemArray[item]);
				this.form.itemsList.push({ name: this.itemArray[item] });
			}
			this.$refs.ruleForm.validate(async (valid) => {
				if (valid) {
					if (this.editId === null) {
						let res = await pointInsert(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					} else {
						let res = await pointUpdate(this.form);
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.success();
						} else {
							this.$message.error(res.msg);
						}
					}
				}
			});
		},
	},
	watch: {
		editId: {
			handler(val) {
				if (val !== null) {
					this.itemArray = []
					this.title = "修改巡检点";
					this.form.id = val;
					pointInfo({ inspectionId: val }).then((res) => {
						this.form = res.data;
						for(let k of this.form.checkItemsList){
							// console.log(k.name);
							this.itemArray.push(k.name)
						}
					});
				} else {
					this.title = "新增巡检点";
				}
			},
			immediate: true,
		},
	},
};
</script>

<style></style>
