<template>
  <div>
    <div class="cardTitle">巡检点</div>
    <searchForm
      :formItem="formItem"
      @getSearch="search($event)"
    ></searchForm>
    <a-button style="margin: 10px" class="add-btn" @click="drawer.show = true"
      >新增巡更点</a-button
    >
    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="pagination"
      @change="handleTableChange"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: selectionChoosed,
      }"
      :row-key="
        (record, index) => {
          return record.id;
        }
      "
    >
      <span slot="action" slot-scope="text, row">
        <a-space>
          <a class="ant-dropdown-link" @click="edit(row.id)">修改</a>
          <a class="ant-dropdown-link" @click="del([row.id])">删除</a>
          <a class="ant-dropdown-link" @click="qrCodeGeneration(row.code)"
            >巡检点二维码</a
          >
        </a-space>
      </span>
    </a-table>
    <div class="action">
      <a-dropdown :disabled="!hasSelected">
        <a-menu slot="overlay" @click="handleMenuClick">
          <a-menu-item key="del"> 批量删除 </a-menu-item>
        </a-menu>
        <a-button> 批量操作 <a-icon type="down" /> </a-button>
      </a-dropdown>
      <span style="margin-left: 8px">
        <template v-if="hasSelected">
          {{ `已选择 ${selectedRowKeys.length} 条` }}
        </template>
      </span>
    </div>
    <addForm
      :show="drawer.show"
      @success="success"
      @addClose="addClose"
      :editId="editId"
    ></addForm>
    <a-modal title="巡检点二维码" :visible="qr.show" @cancel="qr.show=false" width='300px'>
      <!-- 二维码图片 -->
      <div id="qrCodeUrl"></div>
      <!-- 取消按钮 -->
      <span slot="footer" class="dialog-footer">
        <a-button @click="qr.show=false">取消</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { pointList, pointDel } from "@/api/operation/inspection/point.js";
import { formItem, columns, pagination } from "./depend/config";
import addForm from "./depend/form.vue";
export default {
	components: {
		addForm,
	},
	data() {
		return {
			drawer: {
				show: false,
				detail: false,
			},
			qr: {
				show: false,
			},
			editId: null,
			detailId: null,
			tableData: [],
			searchForm: {
				name: undefined,
				code: undefined,
			},
			formItem,
			columns,
			pagination,
			// 选择的index
			selectedRowKeys: [],
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		async getData() {
			let res = await pointList({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				...this.searchForm,
			});
			this.tableData = res.data.rows;
			this.pagination.total = res.data.total;
			this.selectedRowKeys = [];
		},
		search(data){
			this.searchForm = data;
			this.getData()
		},
		edit(id) {
			this.editId = id;
			this.drawer.show = true;
		},
		del(ids) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: async () => {
					let res = await pointDel({ ids: ids });
					if (res.code === 200) {
						this.$message.success(res.msg);
						this.getData();
					} else {
						this.$message.error(res.msg);
					}
				},
			});
		},
		selectionChoosed(data) {
			this.selectedRowKeys = data;
		},
		// 巡检点二维码
		qrCodeGeneration(data) {
			// 二维码弹框显示
			this.qr.show = true;
			// 生成二维码
			this.$nextTick(function () {
				document.getElementById("qrCodeUrl").innerHTML = "";
				let qrCodeUrl = new QRCode("qrCodeUrl", {
					width: 200,
					height: 200,
					text: data,
					colorDark: "#409EFF",
					colorLight: "#fff",
				});
			});
		},
		handleMenuClick(data) {
			if (data.key === "del") {
				this.del(this.selectedRowKeys);
			}
		},
		handleTableChange(pagination) {
			const pager = { ...this.pagination };
			pager.current = pagination.current;
			pager.pageSize = pagination.pageSize;
			this.pagination = pager;
			this.getData();
		},
		addClose() {
			this.drawer.show = false;
			this.editId = null;
		},
		success() {
			this.getData();
		},
	},
	computed: {
		hasSelected() {
			return this.selectedRowKeys.length > 0;
		},
	},
};
</script>
<style lang="less" scoped>
.table-img {
  width: 40px;
  height: 40px;
}
/deep/ #qrCodeUrl > img {
        margin: 0 auto;
    }
</style>
